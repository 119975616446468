import { ColorIcon, colors, HStack, useAlertDialog, VStack } from "goi_common";
import { useAtomValue } from "jotai";
import { useOverlay } from "@toss/use-overlay";

import { isLoggedInAtom } from "@/components/AuthProvider";
import { KakaoLoginBottomSheet } from "@/components/Modals/KakaoLoginBottomSheet";
import {
  HomeMenuPrimaryCard,
  HomeMenuSecondaryCard,
} from "@/containers/HomeContainer/components/HomeMenuCard";
import { useRouter } from "next/router";

import { getObituarySummaryList } from "@/apis/obituaries";
import { useQuery } from "react-query";
import { Circle, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import { getObituaryContacts } from "@/apis/contact";

export const HomeMenuCardSection = () => {
  const isLogeedIn = useAtomValue(isLoggedInAtom);
  const kakoLoginOverlay = useOverlay();
  const router = useRouter();

  const { data: obituariesList, isLoading } = useQuery(
    ["obituariesList"],
    getObituarySummaryList,
    {
      staleTime: 1000 * 5,
    }
  );

  const redirectOnClick = (redirectTo: string) => {
    if (!isLogeedIn) {
      kakoLoginOverlay.open(({ isOpen, close: onClose }) => (
        <KakaoLoginBottomSheet
          isOpen={isOpen}
          onClose={onClose}
          redirectTo={redirectTo}
          event={{
            path: "TODO",
            trigger_and_target: "TODO",
          }}
        />
      ));

      return;
    }

    router.push(redirectTo);
  };

  const { openAlertDialog } = useAlertDialog();

  const { data: contactData, isLoading: isContactLoading } = useQuery({
    queryKey: ["obituaryContacts"],
    queryFn: getObituaryContacts,
  });

  return (
    <section>
      <VStack gap={8} width="100%" padding="20px 20px 0px 20px">
        <HStack gap={8}>
          <HomeMenuPrimaryCard
            icon={<ColorIcon name="icon-ribbon" color="" size="34px" />}
            title="새 부고 작성"
            description="new"
            width="50%"
            onClick={() => redirectOnClick("/bugo/create/")}
          />
          <VStack gap={8} width="50%">
            <HomeMenuSecondaryCard
              icon={
                <ColorIcon name="icon-sticker-circle" color="" size="34px" />
              }
              title="내 부고 목록"
              description={
                isLoading ? (
                  <Skeleton
                    opacity={0.2}
                    width="70%"
                    height="16px"
                    marginTop="2px"
                    colorScheme="red"
                  />
                ) : (
                  `${obituariesList?.count ?? 0}개의 부고장이 있어요`
                )
              }
              count={
                !isLoading && obituariesList?.count ? (
                  <Circle
                    css={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      minWidth: "24px",
                      minHeight: "24px",
                      padding: "0px 7px",
                      background: colors.danger,
                      color: colors.white,
                    }}
                  >
                    {obituariesList?.count}
                  </Circle>
                ) : isLoading ? (
                  <SkeletonCircle
                    position="absolute"
                    opacity={0.2}
                    top="10px"
                    right="10px"
                    width="24px"
                    height="24px"
                  />
                ) : null
              }
              onClick={() => redirectOnClick("/my-bugo/")}
            />

            <HomeMenuSecondaryCard
              icon={<ColorIcon name="icon-call-circle" color="" size="34px" />}
              title="조문객 명단 관리"
              description={
                isContactLoading ? (
                  <Skeleton
                    opacity={0.2}
                    width="70%"
                    height="16px"
                    marginTop="2px"
                  />
                ) : (
                  `${contactData?.length ?? 0}명의 조문객`
                )
              }
              onClick={() => redirectOnClick("/contact/")}
            />
          </VStack>
        </HStack>
        <HomeMenuSecondaryCard
          icon={<ColorIcon name="icon-coin-circle" color="" size="34px" />}
          title="부의금 / 근조화환 관리"
          onClick={() => {
            openAlertDialog({
              content: "현재 준비중이에요",
              buttonText: "확인",
            });
          }}
        />
      </VStack>
    </section>
  );
};
